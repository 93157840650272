import React from 'react';
import PropTypes from 'prop-types';
import Sticky from 'react-sticky-el';

import de from 'date-fns/locale/de';
import {format, parse} from 'date-fns';
import Meta, {appendSeoTitleSuffix} from '../../../Layout/Meta';
import {
	ArticleStyled,
	HeadlineStyled,
	LabelStyled,
	ImageStyled,
	HeaderStyled,
	HeaderContentStyled,
	DateStyled,
	IntroStyled,
	ArticleActionsStyled,
	ArticleContentStyled,
	ArticleFooterStyled,
} from './styles';
import {ResponsiveSwitch} from '../../../../utils/responsiveUtils';
import Section from '../../../Layout/Section/Section';
import Row from '../../../Layout/Grid/Row';
import Column from '../../../Layout/Grid/Column';
import {HeadlineStyledH3} from '../../../Elements/Headline/styles';
import Teaser from '../../../Elements/Teaser/Teaser';
import ReleaseNumber from '../../../Elements/ReleaseNumber/ReleaseNumber';
import ContactSection from '../../../Content/ContactSection/ContactSection';
import {content} from '../../../../assets/language/de';
import ShareSocialMedia from '../../ShareSocialMedia/ShareSocialMedia';

const Article = (props) => {
	const {
		wordpressId,
		slug,
		title,
		releaseNumber,
		relatedContent,
		location,
		components,
		primaryCategory,
		categories,
		excerpt,
		date,
		img,
		metaDescription,
	} = props;
	const colProps = {
		header: {
			md: 8,
			lg: 6,
			offset: {
				md: 2,
				lg: 3,
			},
		},
		article: {
			md: 10,
			lg: 8,
			offset: {
				lg: 1,
			},
			xxl: 6,
		},
		sidebar: {
			md: 2,
			lg: 1,
			offset: {
				lg: 1,
			},
		},
		footer: {
			lg: 4,
		},
	};
	const categoriesStringArray = categories.map((cat) => {
		return cat.name;
	});

	const dateFormat = parse(date, 'MMMM dd, yyyy', new Date());
	const formattedDate = format(dateFormat, 'd. MMMM yyyy', {locale: de});

	const category = 'leben-mit-ms';
	const categoriesSearch = categoriesStringArray.join('|');

	const primaryCategoryKeyword = <meta itemProp="keywords" content={primaryCategory.name} />;
	const label = primaryCategory ? <LabelStyled text={primaryCategory.name} /> : null;

	let relatedContentRow = null;
	let relatedContentSection = null;

	if (relatedContent && relatedContent.length > 0) {
		relatedContentRow = relatedContent.map((relatedPost, index) => {
			const teaserItem = {
				appearance: 'relatedContent',
				title: relatedPost.title,
				label: relatedPost.primary_category,
				link: {
					url: `${relatedPost.slug}`,
					title: relatedPost.title,
					target: '_self',
				},
				image: {
					source_url: '',
					alt_text: relatedPost.title,
					title: relatedPost.title,
					localFile: {
						publicURL: relatedPost.thumbnail,
					},
				},
			};

			return (
				<Column key={relatedPost.id} {...colProps.footer}>
					<Teaser position={index + 1} item={teaserItem} />
				</Column>
			);
		});

		relatedContentSection = (
			<Section showPadding theme="grey">
				<Row>
					<Column>
						<HeadlineStyledH3>{content.relatedContent.headline}</HeadlineStyledH3>
					</Column>
				</Row>
				<Row>{relatedContentRow}</Row>
			</Section>
		);
	}

	return (
		<ArticleStyled className={`article articleCategory_${categoriesSearch}`}>
			<div className="category" style={{display: 'none'}}>
				{category}
			</div>
			<Meta title={appendSeoTitleSuffix(title)} description={metaDescription}>
				{primaryCategoryKeyword}
			</Meta>
			<Section>
				<HeaderStyled>
					<ImageStyled src={img} />
					<HeaderContentStyled>
						<Section showPadding>
							<Row>
								<Column {...colProps.header}>
									{label}
									<HeadlineStyled itemProp="name" dangerouslySetInnerHTML={{__html: title}} />
								</Column>
							</Row>
						</Section>
					</HeaderContentStyled>
				</HeaderStyled>
			</Section>
			<Section className="content stickArea" showPadding>
				<Row>
					<ResponsiveSwitch>
						{(match) => {
							if (match.md) {
								return (
									<Column {...colProps.sidebar}>
										<Sticky
											topOffset={-80}
											bottomOffset={80}
											boundaryElement=".stickArea"
											hideOnBoundaryHit={false}
											stickyStyle={{marginTop: '60px'}}>
											<ArticleActionsStyled
												slug={slug}
												title={title}
												category={primaryCategory ? primaryCategory.name : ''}
												wordpressId={wordpressId}
											/>
										</Sticky>
									</Column>
								);
							}
							// smaller than md
							return null;
						}}
					</ResponsiveSwitch>

					<Column {...colProps.article}>
						<>
							{label}
							<ResponsiveSwitch>
								{(match) => {
									if (match.mdMax) {
										return (
											<HeadlineStyled itemProp="name" dangerouslySetInnerHTML={{__html: title}} />
										);
									}
									// smaller than md
									return null;
								}}
							</ResponsiveSwitch>
							<DateStyled>{formattedDate}</DateStyled>
							<IntroStyled itemProp="description" dangerouslySetInnerHTML={{__html: excerpt}} />
							<ArticleContentStyled>{components}</ArticleContentStyled>
							<ArticleFooterStyled>
								<ReleaseNumber releaseNumber={releaseNumber} />
								<ShareSocialMedia title={title} location={location} />
							</ArticleFooterStyled>
						</>
						<ResponsiveSwitch>
							{(match) => {
								if (match.mdMax) {
									return (
										<Column {...colProps.sidebar}>
											<ArticleActionsStyled
												slug={slug}
												title={title}
												category={primaryCategory ? primaryCategory.name : ''}
												bottom
												wordpressId={wordpressId}
											/>
										</Column>
									);
								}
								// smaller than md
								return null;
							}}
						</ResponsiveSwitch>
					</Column>
				</Row>
			</Section>

			<ContactSection />
			{relatedContentSection}
		</ArticleStyled>
	);
};

Article.propTypes = {
	wordpressId: PropTypes.number.isRequired,
	slug: PropTypes.string.isRequired,
	date: PropTypes.string,
	title: PropTypes.string,
	excerpt: PropTypes.string,
	metaDescription: PropTypes.string,
	img: PropTypes.shape({
		srcSet: PropTypes.string,
		src: PropTypes.string,
	}),
	primaryCategory: PropTypes.shape({
		slug: PropTypes.string,
		name: PropTypes.string,
	}),
	categories: PropTypes.arrayOf(
		PropTypes.shape({
			slug: PropTypes.string,
			name: PropTypes.string,
		})
	),
	releaseNumber: PropTypes.string,
	relatedContent: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			title: PropTypes.string,
			slug: PropTypes.string,
			thumbnail: PropTypes.shape({
				srcSet: PropTypes.string,
				src: PropTypes.string,
			}),
			primaryCategory: PropTypes.shape({
				name: PropTypes.string,
			}),
			categories: PropTypes.arrayOf(
				PropTypes.shape({
					name: PropTypes.string,
				})
			),
		})
	),
	components: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]).isRequired,
};

Article.defaultProps = {
	date: null,
	title: '',
	excerpt: '',
	metaDescription: '',
	img: null,
	releaseNumber: null,
	relatedContent: [],
	primaryCategory: null,
	categories: null,
};

/** @component */
export default Article;
