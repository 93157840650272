import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import Layout from '../components/Layout/Layout';
import Article from '../components/Container/Article/Article/Article';
import {PageTemplateStyled} from './styles';
import WpRow from '../components/WordPress/WpGrid/WpRow';
import WpRow21 from '../components/WordPress/WpGrid/WpRow_2_1';
import WpRow12 from '../components/WordPress/WpGrid/WpRow_1_2';
import WpColumn from '../components/WordPress/WpGrid/WpColumn';
import Meta, {appendSeoTitleSuffix} from '../components/Layout/Meta';

export const BlogPostTemplate = ({
	releaseNumber,
	wordpressId,
	slug,
	relatedContent,
	components,
	primaryCategory,
	categories,
	excerpt,
	location,
	title,
	date,
	img,
	metaDescription,
}) => {
	const articleProps = {
		wordpressId,
		slug,
		date,
		title,
		excerpt,
		location,
		img,
		primaryCategory,
		categories,
		releaseNumber,
		relatedContent,
		components,
		metaDescription,
	};

	return (
		<PageTemplateStyled>
			<Article {...articleProps} />
		</PageTemplateStyled>
	);
};

BlogPostTemplate.propTypes = {
	date: PropTypes.string.isRequired,
	wordpressId: PropTypes.number.isRequired,
	slug: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	excerpt: PropTypes.string,
	metaDescription: PropTypes.string,
	img: PropTypes.shape({
		srcSet: PropTypes.string,
		src: PropTypes.string,
	}),
	primaryCategory: PropTypes.shape({
		wordpress_id: PropTypes.string,
		slug: PropTypes.string,
		name: PropTypes.string,
	}),
	categories: PropTypes.arrayOf(
		PropTypes.shape({
			slug: PropTypes.string,
			name: PropTypes.string,
		})
	),
	releaseNumber: PropTypes.string,
	relatedContent: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			title: PropTypes.string,
			slug: PropTypes.string,
			thumbnail: PropTypes.shape({
				srcSet: PropTypes.string,
				src: PropTypes.string,
			}),
			primary_category: PropTypes.string,
		})
	),
	components: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
};

BlogPostTemplate.defaultProps = {
	releaseNumber: '',
	relatedContent: [],
	excerpt: '',
	metaDescription: '',
	primaryCategory: null,
	categories: [],
	img: null,
	components: null,
};

const reactComponents = {
	row: WpRow,
	row_2_1: WpRow21,
	row_1_2: WpRow12,
	column_1: WpColumn,
	column_2: WpColumn,
};

class BlogPost extends Component {
	/**
	 * Render components dynamically.
	 *
	 * Decides which components to render
	 * based on the data from graphql.
	 */
	renderComponentsDynamically() {
		const {
			data: {wordpressPost: post},
		} = this.props;

		const components = post.acf.components_post;
		const {
			acf: {rowLength},
		} = post;

		const componentsObject = {};

		for (let i = 1; i <= rowLength; i += 1) {
			const filteredEntries = components.filter((c) => {
				return c.belongsToRow === i;
			});
			if (filteredEntries && filteredEntries.length > 0) {
				const rowType = filteredEntries[0].layout;
				componentsObject[i] = {
					rowType,
					elements: filteredEntries,
				};
			}
		}
		return Object.keys(componentsObject).map((key) => {
			const c = componentsObject[key];
			const WpComponent = reactComponents[c.rowType];
			return <WpComponent key={key} content={c} />;
		});
	}

	/**
	 * Render post.
	 */
	render() {
		const {
			location,
			data: {wordpressPost: post},
		} = this.props;

		const relatedContent = post.acf.related_content
			? post.acf.related_content.map((relatedPost) => {
					return {
						id: relatedPost.post.wordpress_id,
						title: relatedPost.post.post_title,
						slug: relatedPost.post.post_path,
						primary_category: relatedPost.primary_category.name,
						thumbnail:
							relatedPost.thumbnail &&
							relatedPost.thumbnail.url &&
							relatedPost.thumbnail.url.localFile &&
							relatedPost.thumbnail.url.localFile.childImageSharp
								? relatedPost.thumbnail.url.localFile.childImageSharp.fluid
								: null,
					};
			  })
			: [];

		const postProps = {
			releaseNumber: post.acf.release_number,
			wordpressId: post.wordpress_id,
			slug: post.path,
			components: this.renderComponentsDynamically(),
			relatedContent,
			primaryCategory: post.primary_category,
			categories: post.categories,
			excerpt: post.excerpt,
			tags: post.tags,
			title: post.title,
			date: post.date,
			img:
				post.featured_media && post.featured_media.localFile
					? post.featured_media.localFile.childImageSharp.fluid
					: null,
			metaDescription: post.yoast_meta.yoast_wpseo_metadesc,
		};

		const trackingProps = {
			wordpressId: post.wordpress_id,
			title: post.title,
			date: post.date,
		};

		return (
			<Layout location={location} trackingProps={trackingProps} className="ap-post">
				<Meta title={appendSeoTitleSuffix(post.title)} description={post.yoast_meta.yoast_wpseo_metadesc} />
				<BlogPostTemplate {...postProps} location={location} />
			</Layout>
		);
	}
}

BlogPost.propTypes = {
	data: PropTypes.shape({
		wordpressPost: PropTypes.object,
	}).isRequired,
	location: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default BlogPost;

export const pageQuery = graphql`
	query BlogPostByID($id: String!) {
		wordpressPost(id: {eq: $id}) {
			id
			wordpress_id
			title
			slug
			path
			content
			excerpt
			date(formatString: "MMMM DD, YYYY")
			slug
			featured_media {
				localFile {
					childImageSharp {
						id
						fluid(srcSetBreakpoints: [800, 250], quality: 80) {
							...GatsbyImageSharpFluid_noBase64
						}
					}
				}
			}
			categories {
				wordpress_id
				slug
				name
			}
			primary_category {
				wordpress_id
				slug
				name
			}
			yoast_meta {
				yoast_wpseo_title
				yoast_wpseo_metadesc
			}
			acf {
				hidden
				persona
				release_number
				rowLength
				related_content {
					post {
						wordpress_id
						post_title
						post_name
						post_path
					}
					primary_category {
						name
					}
					categories {
						name
					}
					thumbnail {
						url {
							localFile {
								childImageSharp {
									fluid(srcSetBreakpoints: [800, 250], quality: 80) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
					}
				}
				components_post {
					__typename
					... on WordPressAcf_headline {
						belongsToRow
						alignItems
						layout
						column
						as
						text
					}
					... on WordPressAcf_text {
						belongsToRow
						alignItems
						layout
						column
						content
					}
					... on WordPressAcf_intro {
						belongsToRow
						alignItems
						layout
						column
						content
					}
					... on WordPressAcf_quote {
						belongsToRow
						alignItems
						layout
						column
						content
					}
					... on WordPressAcf_spotify {
						belongsToRow
						alignItems
						layout
						column
						playlist
					}

					... on WordPressAcf_infobox {
						belongsToRow
						alignItems
						layout
						column
						theme
						content
					}
					... on WordPressAcf_list {
						belongsToRow
						alignItems
						layout
						column
						as
						items {
							key
							title
							text
						}
					}
					... on WordPressAcf_button {
						belongsToRow
						alignItems
						layout
						column
						button_theme
						link {
							title
							url
							target
						}
					}
					... on WordPressAcf_downloadbutton {
						belongsToRow
						alignItems
						layout
						column
						button_theme
						file {
							title
							filename
							url {
								localFile {
									publicURL
								}
							}
						}
					}
					... on WordPressAcf_image {
						belongsToRow
						alignItems
						layout
						column
						appearance
						alignment
						caption
						max_width
						optional_link {
							url
						}
						image {
							source_url
							alt_text
							title
							localFile {
								childImageSharp {
									id
									fluid(srcSetBreakpoints: [800, 250], quality: 80) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
					}
					... on WordPressAcf_accordion {
						belongsToRow
						layout
						column
						stil
						items {
							title
							content {
								content_elements {
									text
									as
									acf_fc_layout
									content
									theme
									appearance
									alignment
									caption
									button_theme
									link {
										title
										url
										target
									}
									optional_link {
										url
									}
									items {
										key
										title
										text
									}
									max_width
									image {
										source_url
										alt_text
										title
										localFile {
											extension
											childImageSharp {
												id
												fluid(srcSetBreakpoints: [800, 250], quality: 80) {
													...GatsbyImageSharpFluid_noBase64
												}
											}
										}
									}
								}
							}
						}
					}
					... on WordPressAcf_releasenumbers {
						belongsToRow
						alignItems
						layout
						column
						content
					}

					... on WordPressAcf_special {
						belongsToRow
						alignItems
						layout
						column
						element
					}
					... on WordPressAcf_video {
						belongsToRow
						alignItems
						layout
						column
						caption
						video_embed
					}
					... on WordPressAcf_accordion {
						belongsToRow
						layout
						column
						stil
						items {
							title
							content {
								content_elements {
									text
									as
									acf_fc_layout
									content
									theme
									appearance
									alignment
									caption
									button_theme
									link {
										title
										url
										target
									}
									optional_link {
										url
									}
									items {
										key
										title
										text
									}
									max_width
									image {
										source_url
										alt_text
										title
										localFile {
											extension
											childImageSharp {
												id
												fluid(srcSetBreakpoints: [800, 250], quality: 80) {
													...GatsbyImageSharpFluid_noBase64
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
